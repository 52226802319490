<template>
  <div>
    <el-image :src="require('@/assets/image/TradeTip/stabdardTariffHead.png')"></el-image>
    <div class="game-list">
      <div class="game-item" v-for="(item,index) in gameList" :key="index" @click="goToCus(item.cus)">
        <div class="game-info">
          <el-image class="game-img" :src="item.img"></el-image>
          <div class="title">
            <div>{{ item.title1 }}</div>
          </div>
        </div>
        <div class="game-cus" >
          <div>{{ item.gameName }}</div>
          <div style="cursor: pointer">联系在线客服<i class="el-icon-arrow-right"></i></div>
        </div>
      </div>
    </div>
    <!--    <div class="hint"><i class="el-icon-warning"></i> 跳转失败请对照客服列表手动添加 ，客服QQ均为企业客服QQ，普通QQ均为骗子。</div>-->
    <div style="text-align: center">
      <el-image :src="require('@/assets/image/TradeTip/stabdardTariffOther.png')"
                style="width: 318px;margin-top:54px;margin-bottom: 34px"></el-image>
    </div>
    <div class="otherDescription">
      <div class="item">
        跳转在线客服之后，以客服发送的企业微信二维码为准辨别真伪。
      </div>
      <div class="item">
        买家付款以后，上号验号，与卖家描述相符即可过户账号资料。<br/>
        平台会默认收取卖家身份证信息并验证真假，如不能提供请提前告知平台。
      </div>
      <div class="item">
        <span>违约责任参考买卖家须知</span>
        <router-link :to="{name:'TradeTip',query:{id:'108',parent_id:'83'}}" class="itemClick" >
         &ensp;---------&ensp;卖家须知 <i class="el-icon-arrow-right"></i>
        </router-link>
      </div>
      <div class="item">
        若有特殊情况手续费发生变动的情况，以买、卖、平台三方最终协商为基准，交易前请与客服核对清楚并确认再开始交易流程。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "standardTariff",
  data() {
    return {
      gameList: [
        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001021254_825941.png',
          gameName: '天刀端游',
          title1: '号价的5%（最低200）+30上架费（上限2030）',
          title2: '天刀端游客服-奶茶 微信号：pxb7nc',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/2'
        },
        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001024106_068248.png',
          gameName: '天刀手游',
          title1: '号价的5%（最低50上限2030）',
          title2: '天刀手游客服-奶茶 微信号：pxb7nc',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/2'
        },
        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001021952_847049.png',
          gameName: '剑灵端游',
          title1: '号价的5%（最低50）+30上架费',
          title2: '剑灵端游客服-大头 微信号：pxb7dt',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/3'
        },

        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001024209_495652.png',
          gameName: 'QQ三国',
          title1: '3%（最低30）',
          title2: '螃蟹客服肉肉  微信号：pxb7rr',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/4'
        },
        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001023208_034052.png',
          gameName: '原神',
          title1: '5%（最低30上限1000）',
          title2: ' 原神客服-贝贝  微信号：pxb7bb',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/7'
        },
        {
          img: 'https://images.pxb7.com/images/game/20211215/20211215171107_570079.png',
          gameName: '幻塔',
          title1: '5%（最低30）',
          title2: ' 原神客服-贝贝  微信号：pxb7bb',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/7'
        },
        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001023612_995562.png',
          gameName: '永劫无间',
          title1: '5%（最低30）',
          title2: ' 原神客服-贝贝  微信号：pxb7bb',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/6'
        },
        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001023736_148771.png',
          gameName: '绝地求生',
          title1: '5%（最低50）',
          title2: '王者荣耀客服-路飞   微信号：pxzhanghaohs',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/21'
        },

        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001024257_797181.png',
          gameName: '王者荣耀',
          title1: '5%（最低50）',
          title2: '王者荣耀客服-路飞   微信号：pxzhanghaohs',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/10'
        },
        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001023336_673717.png',
          gameName: 'DNF-地下城与勇士',
          title1: '5%（最低50）',
          title2: ' DNF客服-天天 微信号：pxb7tt',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/5'
        },
        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001022953_568399.png',
          gameName: '英雄联盟',
          title1: '5%（最低50）',
          title2: '王者荣耀客服-路飞   微信号：pxzhanghaohs',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/10'
        },
        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001022112_462644.png',
          gameName: '和平精英',
          title1: '5%（最低50）',
          title2: '和平精英客服-路飞   微信号：pxzhanghaohs',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/10'
        },
        {
          img: 'https://images.pxb7.com/images/game/20211221/20211221172834_266054.jpg',
          gameName: '穿越火线',
          title1: '5%（最低50）',
          title2: '和平精英客服-路飞   微信号：pxzhanghaohs',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/10'
        },
        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001022743_811556.png',
          gameName: '超激斗梦境',
          title1: '5%（最低10）',
          title2: '和平精英客服-路飞   微信号：pxzhanghaohs',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/5'
        },


      ]
    }
  },
  methods: {
    goToCus(url) {
      window.open(url)
    }
  }
}
</script>

<style scoped lang="less">
.game-list {
  margin-top: 32px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .game-item {
    width: 380px;
    background: #FFFFFF;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px 20px 0 20px;
    cursor: pointer;
    .game-info {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(151, 151, 151, 0.15);

      .game-img {
        min-width: 60px;
        max-width: 60px;
        margin-right: 20px;
        border-radius: 12px;
      }

      .title {
        font-size: 16px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #5B5B5B;
        line-height: 22px;
      }
    }

    .game-cus {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 8px 0 11px 0;
      font-size: 14px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
      line-height: 20px;
    }
  }
}

.hint {
  font-size: 16px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #A3B2C4;
  line-height: 22px;
  text-align: center;
}

.otherDescription {
  padding: 0 40px;
  font-size: 16px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #5B5B5B;
  line-height: 22px;
  margin-bottom: 100px;

  .item {
    margin-bottom: 9px;
    padding-left: 14px;
    position: relative;
    .itemClick{
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #5571a9;
      cursor: pointer;
    }
    .itemClick:hover{
      color: #ffc957;
    }

    &::after {
      content: '';
      width: 8px;
      height: 8px;
      background-color: rgba(91, 91, 91, 0.95);
      position: absolute;
      top: 6px;
      left: 0;
      border-radius: 2px;
    }

    span {
      font-weight: bold;
    }
  }
}
</style>
