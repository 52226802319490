<template>
  <div>
    <div class="title-box">
      <div class="title1">服务协议</div>
      <div class="title2">uushouyou代售服务协议详情</div>
<!--      <el-image class="img" :src="require('@/assets/image/TradeTip/serviceAgreement.png')"></el-image>-->
    </div>
    <div class="ql-container ql-snow">
      <p class="tradeTipContent ql-editor" v-html="pageInfo"></p>
    </div>
  </div>
</template>

<script>
import {apiPageInfo} from "@/request/API";

export default {
  name: "serviceAgreement",
  data() {
    return {
      pageInfo: ''
    }
  },
  mounted() {
    this.getPageInfo()
  },
  methods: {
    getPageInfo() {
      apiPageInfo({
        id: 63
      }).then(res => {
        this.pageInfo = res.data.content
      })
    }
  },
}
</script>

<style scoped lang="less">
.title-box {
  width: 736px;
  height: 86px;
  background: linear-gradient(93deg, #EAF4FF 0%, rgba(255, 255, 255, 0) 100%);
  margin-top: 43px;
  padding-left: 62px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .title1 {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #6E7884;
    line-height: 28px;
  }

  .title2 {
    font-size: 16px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #6E7884;
    line-height: 22px;
  }

  .img {
    width: 112px;
    position: absolute;
    left: 35px;
  }

}

.ql-container.ql-snow {
  border: none;
  padding: 0 58px 116px 62px;
  margin-top: 21px;
  font-size: 16px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #71787E;
  line-height: 29px;
}
</style>
