<template>
  <div id="per_center">
    <div class="nav_menu">
      <div class="nav_menu_title">
        <div class="nav_menu_title_img">
          <img src="@/assets/image/TradeTip/tradeTipLogo.png" alt="">
        </div>
        <div class="nav_menu_title_text">交易必看</div>
      </div>
      <div class="nav_menu_content">
        <el-menu
            router
            :default-active="defaultActive"
            :unique-opened="true"
            class="nav_menu-list"
            @select="handleSelect"
            @open="handleOpen"
            @close="handleClose">
          <div class="menuList" v-for="item in menu" :key="item.id">
<!--            v-for="item in submenu" :key="item.id"-->
            <!-- 交易流程 -->
            <el-menu-item v-if="!item._child" :index="item.id.toString()"
                           :route="{name:'TradeTip',query:{id:item.id,parent_id:item.parent_id}}">
              <span slot="title">{{ item.page_name }}</span>
              <div ></div>
            </el-menu-item>
            <!-- 交易须知 -->
            <el-submenu v-else :index="item.id.toString()">
              <template slot="title">
                <span> {{ item.page_name }}</span>
                <div ></div>
              </template>
              <el-menu-item :index="childItem.id.toString()" v-for="childItem in item._child" :key="childItem.id"
                            :route="{name:'TradeTip',query:{id:childItem.id,parent_id:item.parent_id}}">
                <div></div>
                {{ childItem.page_name }}
              </el-menu-item>
            </el-submenu>
          </div>


        </el-menu>
      </div>
    </div>
    <div class="main">
<!--      &lt;!&ndash;   中介担保   &ndash;&gt;-->
<!--      <intermediary-guarantee v-if="ref == 513"></intermediary-guarantee>-->
      <!--   服务协议   -->
      <service-agreement v-if="ref == 63"></service-agreement>
      <!--   分期购业务   -->
      <installment v-else-if="ref == 110"></installment>
      <!--   资费标准   -->
      <standardTariff v-else-if="ref == 111"></standardTariff>
      <!--   投诉建议   -->
      <suggestions v-else-if="ref == 112"></suggestions>
      <Content :key1='ref' v-else></Content>
      <!--      <router-view></router-view>-->
    </div>
  </div>

</template>

<script>
import Content from './content'
import {apiNavPage} from "@/request/API";
// import IntermediaryGuarantee from "./intermediaryGuarantee";
import Installment from "./installment";
import ServiceAgreement from "./serviceAgreement";
import StandardTariff from "./standardTariff";
import Suggestions from "./suggestions";

export default {
  name: '',
  props: {},
  components: {
    ServiceAgreement,
    Installment,
    StandardTariff,
    Suggestions,
    Content
  },
  data() {
    return {
      ref: this.$route.query.id.toString(),
      parent_id: this.$route.query.parent_id,
      menu: [],
      submenu: []
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      this.ref = key.toString()
    },
    handleOpen(key, keyPath) {

    },
    handleClose(key, keyPath) {
    },
    getNavPage() {
      apiNavPage({
        id: this.$route.query.parent_id
      }).then(res => {
        res.data.forEach((item, index) => {
          // if ('_child' in item) {
          //   this.submenu.push(item)
          // } else {
            this.menu.push(item)
          // }
        })
      })
    }
  },
  mounted() {
    this.getNavPage()
  },
  watch: {},
  computed: {
    defaultActive() {
      this.submenu.forEach((item, index) => {
        if (this.$route.query.id == item.id) {
          return item._child[0].id.toString()
        }
      })
      return this.$route.query.id.toString()
    }
  },
  created() {
  },
  beforeRouteUpdate(to, from, next) {
    this.ref = to.query.id.toString()
    next()
  }
}
</script>

<style lang='less' scoped>

#per_center {
  width: 1200px;
  margin-top: 14px;
  display: flex;
  flex-direction: row;

  .nav_menu {
    width: 243px;
    //height: 727px;
    margin-right: 9px;
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      .nav_menu_title {
        height: 72px;
        background: #FFC121;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 20px 0 32px;

        .nav_menu_title_img {
          width: 30px;
          height: 30px;
          margin-right: 7px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .nav_menu_title_text {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          margin-right: 58px;
        }
      }

      .nav_menu_content {
        padding: 11px 0 15px 0;
        //padding-top: 11px;
        min-height: 644px;
        background: #FFFFFF;
        border-radius: 0px 0px 20px 20px;

        .nav_menu-list > .menuList > .el-menu-item {
          height: 40px;
          line-height: 40px;
          padding-left: 59px !important;
          position: relative;
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #5B5B5B;

          div {
            display: inline-block;
            width: 9px;
            height: 9px;
            border-radius: 5px;
            background: #000000;
            position: absolute;
            top: 17px;
            left: 30px;
          }
        }

        .nav_menu-list > .menuList > .is-active, .nav_menu-list > .menuList > .el-menu-item:hover {
          color: #FFFFFF !important;
          background: #283748;

          div {
            background: #FFFFFF;
          }
        }

        /deep/ .el-menu-item, /deep/ .el-submenu__title {
          height: 40px;
          line-height: 40px;
          padding-left: 59px !important;
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #5B5B5B;
        }

        /deep/ .is-active > .el-submenu__title {
          color: #FFFFFF !important;

          div {
            background: #FFFFFF !important;
          }
        }

        /deep/ .el-submenu__title:hover, .el-submenu__title:focus {
          color: #FFFFFF;
          background: #283748;

          div {
            background: #FFFFFF !important;
          }
        }

        /deep/ .el-submenu > .el-submenu__title {
          div {
            display: inline-block;
            width: 9px;
            height: 9px;
            border-radius: 5px;
            background: #000000;
            position: absolute;
            top: 17px;
            left: 30px;
          }
        }

        /deep/ .el-menu--inline {
          background: #F4F4F4;
        }

        /deep/ .el-menu--inline > .el-menu-item {
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #8F8F8F;
        }

        /deep/ .el-menu--inline > .el-menu-item:hover {
          background: #E2E2E2;
          position: relative;

          div {
            position: absolute;
            width: 2px;
            height: 16px;
            background: #8F8F8F;
            border-radius: 1px;
            top: 12px;
            left: 35px;
          }
        }

        /deep/ .el-submenu > .el-menu--inline > .is-active {
          background: #E2E2E2;
          position: relative;

          div {
            position: absolute;
            width: 2px;
            height: 16px;
            background: #8F8F8F;
            border-radius: 1px;
            top: 12px;
            left: 35px;
          }
        }

        /*菜单关闭*/
        /deep/ .el-submenu > .el-submenu__title .el-submenu__icon-arrow {
          -webkit-transform: rotateZ(-90deg);
          -ms-transform: rotate(-90deg);
          transform: rotateZ(-90deg);
        }
        /*菜单展开*/
        /deep/ .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
          -webkit-transform: rotateZ(0deg);
          -ms-transform: rotate(0deg);
          transform: rotateZ(0deg);
        }
      }

  }

  .main {
    width: 951px;
    //height: 727px;
    background: #FFFFFF;
  }
}
</style>
