<template>
  <div class="suggestions">
    <div class="head">
      <img src="@/assets/image/TradeTip/suggestionsBG.png" alt="">
    </div>
    <div style="display: flex;
align-items: center">
      <div class="cardList">
        <div class="wx_cards">
          <img src="@/assets/image/TradeTip/suggestionsWX02.png" alt="">
        </div>
        <div class="cards">
          <img src="@/assets/image/TradeTip/suggestionsCard01.png" alt="" style="cursor: pointer"  @click="goToCus('https://tb.53kf.com/code/client/1d02d00edbb13878088ba0b015676b7e9/1')">
          <img src="@/assets/image/TradeTip/suggestionsCard02.png" alt="" style="margin: 10px 0">
          <img src="@/assets/image/TradeTip/suggestionsCard03.png" alt="">
        </div>
      </div>
    </div>

    <input type="text" id="input" />
  </div>
</template>

<script>
export default {
  name: "suggestions",
  methods:{
    goToCus(url) {
      window.open(url)
    },

    copyWechat(){
      var text = 'pxb7vip';
      var input = document.getElementById("input");
      input.value = text; // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand('copy'); // 执行浏览器复制命令
      this.$message({
        type: "success",
        center: true,
        message: '复制成功'
      })
    }
  }
}
</script>

<style scoped lang="less">
.suggestions {

  .head {
    width: 100%;
    img{
      width: 100%;
      object-fit: none;
    }
  }

  .cardList {
    padding: 0 20px 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .wx_cards{
      display: flex;
      align-items: center;
      margin: 0 10px 0 90px;
    }
  }

  #input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -10;
  }
}

</style>
